/**
 * Logs a deprecation warning for a given prop.
 * @param {Validator} validator - A validator function.
 * @param {string} [instead=''] - String to append to the console warning.
 *                                Useful to suggest an alternative to the deprecated prop.
 * @return {function(*=, *=, ...[*]): *}
 * @example
 *    Component.propTypes = {
 *      a: PropTypes.bool,
 *      b: deprecated(PropTypes.bool, 'Please use prop "z" instead.')
 *      z: PropTypes.bool
 *    }
 */
export default function deprecated(validator, instead = '') {
  return (props, propName, ...rest) => {
    if (props[propName] !== null && props[propName] !== undefined) {
      // eslint-disable-next-line no-console
      console.warn(`Bronson React → Prop "${propName}" is deprecated. ${instead}`)
    }
    return validator(props, propName, ...rest)
  }
}
