import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Flickity from 'react-flickity-component'

const DEFAULT_AUTO_PLAY = 5000

/**
 * The Carousel component will show a slideshow and can contain a series of images.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-carousel--carousel.html
 *
 * variants:
 * - fullscreen - adds control for fullscreen mode (TODO: not yet supported)
 * - autoplay - automatically start sliding (default: 5000ms), see {@link autoplay}
 * - always show controls - always show the controls, independent of brand and viewport, see {@link buttonsVisible}
 * - show on hover - show controls only on hover, independent of brand and viewport, see {@link buttonsOnHover}
 * - counter automatic - by default enabled (with more than 5 items show pagination instead of 'dots')
 * - counter enforced - always show pagination instead of "dots" to switch between slides, see {@link counterEnforced}
 * - fixed aspect ratio - set the intrinsic aspect ratio for the items, see {@link itemAspectRatio} and {@link objectFitContain}
 *
 * @param {string} [className] - additional classes on main element
 * @param {boolean} [fullscreen] - fullscreen variant (TODO: not yet supported)
 * @param {(boolean | number)} [autoplay] - autoplay variant, either use 'boolean' or 'number' as intervall in ms
 * @param {boolean} [buttonsVisible] - 'always show controls' variant
 * @param {boolean} [buttonsOnHover] - 'show on hover' variant
 * @param {boolean} [counterEnforced] - 'counter enforced' variant
 * @param {boolean} [itemAspectRatio] - 'fixed aspect ratio' variant, set the ratio, e.g. '16:9'
 * @param {boolean} [objectFitContain] - set to not crop the images items
 * @param {boolean} [wrapAround] - wrap around at end of the slider (infinite sliding)
 * @param {Carousel.Image[]} children - items to show in Carousel, use {@link Carousel.Image}
 * @param {string} [testId] - data-testid attribute on main element
 * @param {Object} [otherProps] - other props to be passed to main element
 * @return {JSX.Element} - Carousel component
 *
 */
export function Carousel({
  className,
  fullscreen,
  autoPlay,
  buttonsVisible,
  buttonsOnHover,
  counterEnforced,
  itemAspectRatio,
  objectFitContain,
  wrapAround,
  children,
  testId,
  ...otherProps /* in <div> tag */
}) {
  const [flickityRef, setFlickityRef] = useState(null)

  let autoPlayInterval = false
  if (typeof autoPlay === 'number') {
    autoPlayInterval = autoPlay
  } else if (autoPlay) {
    autoPlayInterval = DEFAULT_AUTO_PLAY
  }

  const flickityOptions = {
    cellSelector: '.c-carousel__item',
    imagesLoaded: true,
    wrapAround,
    autoPlay: autoPlayInterval,
    fullscreen,
  }
  // TODO: flickity-fullscreen not implemented by react-flickity-component, need to implement by our own + Browser History

  // sets 'data-slider-counter' attribute for counter enforced variant
  useEffect(() => {
    if (flickityRef && counterEnforced) {
      flickityRef.element.setAttribute('data-slider-counter', true)
    }
  }, [flickityRef, counterEnforced])

  // sets 'data-testid' attribute on main element
  useEffect(() => {
    if (flickityRef && testId) {
      flickityRef.element.setAttribute('data-testid', testId)
    }
  }, [flickityRef, testId])

  const divClassNameList = classNames(
    'c-carousel',
    'js-carousel',
    {
      'c-carousel--buttons-visible ': buttonsVisible,
      'c-carousel--buttons-on-hover ': buttonsOnHover,
    },
    className
  ).trim()

  return (
    <>
      <Flickity
        {...otherProps}
        className={divClassNameList}
        flickityRef={(c) => setFlickityRef(c)}
        options={flickityOptions}
      >
        {
          React.Children.map(children, (child) =>
            React.cloneElement(child, { itemAspectRatio, objectFitContain })
          ) /* Use 'Carousel.Image' component. */
        }
      </Flickity>
    </>
  )
}

Carousel.propTypes = {
  className: PropTypes.string,
  fullscreen: PropTypes.bool,
  autoPlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  buttonsVisible: PropTypes.bool,
  buttonsOnHover: PropTypes.bool,
  counterEnforced: PropTypes.bool,
  itemAspectRatio: PropTypes.string,
  objectFitContain: PropTypes.bool,
  wrapAround: PropTypes.bool,
  children: PropTypes.node,
  testId: PropTypes.string,
}

/**
 * Image items of {@link Carousel}.
 * hint: 'itemAspectRatio' and 'objectFitContain' are set by parent {@link Carousel}
 *
 * @param {string} [className] - additional classes on img element
 * @param {string} imgSrc - image source
 * @param {string} [imgSrcSet] - image source set
 * @param {string} [imgSizes] - image sizes
 * @param {string} [altText] - alt text on img
 * @param {Object} [otherProps] - other props to be passed to main element
 * @return {JSX.Element} - Carousel component
 *
 */
function CarouselImage({
  className,
  imgSrc,
  imgSrcSet,
  imgSizes,
  altText,
  itemAspectRatio,
  objectFitContain,
  ...otherProps /* in <div> tag */
}) {
  const divClassNameList = classNames('c-carousel__item ', {
    'o-ratio': itemAspectRatio,
    [`o-ratio--${itemAspectRatio}`]: itemAspectRatio,
  }).trim()

  const imgClassNameList = classNames(
    'c-carousel__image ',
    {
      'u-object-fit-contain': objectFitContain,
      'o-ratio__content': itemAspectRatio,
    },
    className
  ).trim()

  return (
    <div {...otherProps} className={divClassNameList}>
      <img className={imgClassNameList} srcSet={imgSrcSet} src={imgSrc} sizes={imgSizes} alt={altText} />
    </div>
  )
}

CarouselImage.propTypes = {
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  imgSrcSet: PropTypes.string,
  imgSizes: PropTypes.string,
  altText: PropTypes.string,
}

CarouselImage.displayName = 'Carousel.Image'
Carousel.Image = CarouselImage
