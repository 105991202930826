import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const TabsContext = React.createContext({})

export const useTabSelector = (defaultSelected) => {
  const [selected, setSelected] = useState(defaultSelected)
  return [selected, setSelected]
}

/*
 * Bronson Tab component.
 *
 * Generated React component. Do not modify.
 */
export function Tabs({
  defaultSelected,
  selected,
  onChangeSelected,
  className,
  testId,
  children,
  ...otherProps /* in <div> tag */
}) {
  // hint: to be downward compatible we still use this internal state when defaultSelected is available
  const [currSelected, setCurrSelected] = useState(defaultSelected)

  const contextValue = defaultSelected
    ? {
        // query whether the tab id is selected
        isSelected: (tabId) => currSelected === tabId,
        // callback function to set the selected tab
        setSelected: (tabId) => setCurrSelected(tabId),
      }
    : {
        // query whether the tab id is selected
        isSelected: (tabId) => selected === tabId,
        // callback function to set the selected tab
        setSelected: (tabId) => onChangeSelected(tabId),
      }

  // generated
  const classNameList = classNames(
    {
      'c-tabs ': true,
      'js-tabs ': true,
    },
    className
  ).trim()

  // generated main result
  return (
    <div {...otherProps} className={classNameList} data-testid={testId}>
      <TabsContext.Provider value={contextValue}>
        {children /* Use 'Tabs.Header' and 'Tabs.Content' components. */}
      </TabsContext.Provider>
    </div>
  )
}

// hint: either use 'defaultSelected' or ['selected, 'onChangeSelected']
Tabs.propTypes = {
  defaultSelected: PropTypes.string, // set the initial selected tab (=default tab)
  selected: PropTypes.string, // change the selected tab from outside via this property
  onChangeSelected: PropTypes.func, // useTabSelector hook provides default logic to update current tab and handle selected tab state
  className: PropTypes.string,
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'tabs-item'. Use 'Tabs.Header' and 'Tabs.Content' components.
}

function TabsHeader({ className, testId, children, ...otherProps }) {
  // generated
  const ulClassNameList = classNames(
    {
      'c-tabs__nav ': true,
      'js-tabs__nav ': true,
    },
    className
  ).trim()

  return (
    <nav {...otherProps} data-testid={testId}>
      <ul className={ulClassNameList}>{children}</ul>
    </nav>
  )
}

TabsHeader.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node,
}

TabsHeader.displayName = 'Tabs.Header'
Tabs.Header = TabsHeader

/*
 * Bronson TabHeader component.
 *
 */
function TabsHeaderItem({ tabId, className, icon, disabled, testId, children, ...otherProps /* in <li> tag */ }) {
  const context = React.useContext(TabsContext)

  // generated
  const buttonClassNameList = classNames(
    {
      'c-tabs__nav-link ': true,
      'js-tabs__nav-link ': true,
      'is-disabled ': disabled,
      'is-active ': !disabled && context.isSelected(tabId),
    },
    className
  ).trim()

  // generated
  const iClassNameList = classNames({
    'c-tabs__icon ': true,
    'c-icon ': true,
    [`c-icon--[${icon}] `]: true,
  }).trim()

  // generated
  function renderIfTabsItemIcon() {
    if (icon) {
      return <i className={iClassNameList} />
    }
    return null
  }

  // generated main result
  return (
    <li {...otherProps} className="c-tabs__nav-item" data-testid={testId}>
      <button
        className={buttonClassNameList}
        aria-expanded={!disabled && context.isSelected(tabId)}
        tabIndex={!disabled && context.isSelected(tabId) ? '-1' : null}
        onClick={() => !disabled && context.setSelected(tabId)}
        type="button"
      >
        {renderIfTabsItemIcon()}
        <span className="c-tabs__text">{children}</span>
      </button>
    </li>
  )
}

TabsHeaderItem.propTypes = {
  tabId: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string, // Bronson template: 'tabs-item-icon'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'tabs-item-text'.
}

TabsHeaderItem.displayName = 'Tab.HeaderItem'
Tabs.HeaderItem = TabsHeaderItem

function TabsContent({ testId, children, ...otherProps }) {
  return (
    <div data-testid={testId} {...otherProps}>
      {children}
    </div>
  )
}

TabsContent.propTypes = {
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node,
}

TabsContent.displayName = 'Tabs.Content'
Tabs.Content = TabsContent

/*
 * Bronson TabsItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function TabsContentItem({ tabId, className, testId, children, ...otherProps /* in <div> tag */ }) {
  const context = React.useContext(TabsContext)

  // generated
  const classNameList = classNames(
    {
      'c-tabs__content ': true,
      'js-tabs__content ': true,
      'js-is-ready ': true,
    },
    className
  ).trim()

  // generated main result
  return (
    <div
      {...otherProps}
      className={classNameList}
      aria-hidden={context.isSelected(tabId) ? 'false' : 'true'}
      data-testid={testId}
    >
      {children}
    </div>
  )
}

TabsContentItem.propTypes = {
  tabId: PropTypes.string.isRequired,
  className: PropTypes.string,
  testId: PropTypes.string, // Added for data-testid attribute.
}

TabsContentItem.displayName = 'Tabs.ContentItem'
Tabs.ContentItem = TabsContentItem
