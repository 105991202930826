/* eslint-disable jsx-a11y/aria-role */
// TODO: @Bronson check for valid aria role, 'text' is abstract role!
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson PriceBox component.
 *
 * Generated React component. Do not modify.
 */
export function PriceBox({
  standalone,
  header,
  discountValue,
  ariaLabelDiscountValue,
  discountOldPrice,
  ariaLabelDiscountOldPrice,
  price,
  ariaLabelPrice,
  additionalInfo,
  testId,
  ...otherProps /* in Complex tag name tag */
}) {
  // generated
  // tag containing a variable or condition
  const CustomTag = `${standalone ? 'article' : 'div'}`

  // generated
  const ClassNameList = classNames({
    'c-price-box ': true,
    'c-price-box--integrated ': !standalone,
  }).trim()

  // generated
  // tag containing a variable or condition
  const CustomTag2 = `${standalone ? 'header' : 'div'}`

  // generated
  function renderIfDiscountValue() {
    if (discountValue) {
      return (
        <span className="c-tag c-tag--negative c-price-box__discount__value">
          <span className="c-tag__label" aria-label={`${ariaLabelDiscountValue} ${discountValue}`} role="text">
            {discountValue}
          </span>
        </span>
      )
    }
    return null
  }

  // generated
  function renderIfDiscountOldPrice() {
    if (discountOldPrice) {
      return (
        <del
          className="c-price-box__discount__old-price"
          aria-label={`${ariaLabelDiscountOldPrice} ${discountOldPrice}`}
          role="text"
        >
          {discountOldPrice}
        </del>
      )
    }
    return null
  }

  // generated
  function renderIfDiscount() {
    if (discountOldPrice || discountValue) {
      return (
        <div className="c-price-box__discount">
          {renderIfDiscountValue()}
          {renderIfDiscountOldPrice()}
        </div>
      )
    }
    return null
  }

  // generated
  function renderIfAdditionalInfo() {
    if (additionalInfo) {
      return (
        <div className="c-price-box__additional-info">
          <p className="c-price-box__additional-info__text">{additionalInfo}</p>
        </div>
      )
    }
    return null
  }

  // generated main result
  return (
    <CustomTag {...otherProps} data-testid={testId} className={ClassNameList}>
      <CustomTag2 className="c-price-box__header">{header}</CustomTag2>
      <div className="c-price-box__body">
        <div className="c-price-box__body__content">
          {renderIfDiscount()}
          <span className="c-price-box__price" aria-label={`${ariaLabelPrice} ${price}`} role="text">
            {price}
          </span>
        </div>
        {renderIfAdditionalInfo()}
      </div>
    </CustomTag>
  )
}

PriceBox.propTypes = {
  standalone: PropTypes.bool, // Bronson template: 'standalone'.
  header: PropTypes.node.isRequired, // Bronson template: 'header'.
  discountValue: PropTypes.node,
  ariaLabelDiscountOldPrice: PropTypes.node,
  discountOldPrice: PropTypes.node,
  ariaLabelDiscountPrice: PropTypes.node,
  price: PropTypes.node.isRequired,
  ariaLabelPrice: PropTypes.node,
  additionalInfo: PropTypes.node, // Bronson template: 'additional-info'.
  testId: PropTypes.string, // Added for data-testid attribute.
}
