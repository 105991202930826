import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson Spinner component.
 *
 * Generated React component. Do not modify.
 */
export function Spinner({
  className,
  testId,
  small,
  center,
  fullPage,
  section,
  children,
  busy = true,
  ...otherProps /* in <div> tag */
}) {
  // hint: combination of text and section for spinner is not (yet) possible
  const hasText = !section && children

  // generated
  const divClassNameList = classNames(
    {
      'c-spinner ': true,
      // main class modifier convenience prop extension
      'c-spinner--small ': small, // Convenience prop from Bronson variants.
      'c-spinner--center ': center, // Convenience prop from Bronson variants.
      'c-spinner--full-page ': fullPage, // Convenience prop from Bronson variants.
      'c-spinner--text ': hasText, // Convenience prop from Bronson variants.
      'c-spinner--section ': section, // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  function renderLoader() {
    return (
      <div className="c-spinner__loader">
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
      </div>
    )
  }

  function renderIfHasText() {
    if (hasText) {
      return (
        <div className="c-spinner__content">
          {renderLoader()}
          <div className="c-spinner__text">{children}</div>
        </div>
      )
    }
    return null
  }

  function renderIfNotHasText() {
    if (!hasText) {
      return (
        <>
          {children}
          {renderLoader()}
        </>
      )
    }
    return null
  }

  // generated main result
  return (
    <div {...otherProps} data-testid={testId} className={divClassNameList} aria-live="polite" aria-busy={busy}>
      {renderIfHasText()}
      {renderIfNotHasText()}
    </div>
  )
}

Spinner.propTypes = {
  className: PropTypes.string, // Bronson template: 'spinner-modifier'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'spinner-text'.
  /* Convenience props */
  small: PropTypes.bool, // Convenience prop for c-spinner--small (Bronson template: 'spinner-modifier').
  center: PropTypes.bool, // Convenience prop for c-spinner--center (Bronson template: 'spinner-modifier').
  fullPage: PropTypes.bool, // Convenience prop for c-spinner--full-page (Bronson template: 'spinner-modifier').
  section: PropTypes.bool, // Convenience prop for c-spinner--section (Bronson template: 'spinner-modifier').
  busy: PropTypes.bool, // change visibility, default 'true' (= visible); typically not needed as often conditional rendering is used and preferred
}
