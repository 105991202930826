import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson TileSelect component.
 *
 * Generated React component. Do not modify.
 */
export function TileSelect({
  iconMeaning,
  error,
  radioGroupName,
  inputType,
  checked,
  defaultChecked,
  icon,
  iconHiddenOnSmallScreens,
  id,
  value,
  title,
  children,
  disabled,
  buttonText,
  onClick,
  onChange,
  testId,
  ...otherProps /* in <div> tag */
}) {
  // generated
  const divClassNameList = classNames({
    'c-tile-select ': true,
    'is-error ': error,
  }).trim()

  // generated
  const iClassNameList = classNames({
    'c-tile-select__icon ': true,
    'c-tile-select__icon--hidden-on-small-screens ': iconHiddenOnSmallScreens,
    'c-icon ': true,
    [`c-icon--[${icon}] `]: true,
  }).trim()

  // generated
  function renderUnlessIconHiddenOnSmallScreens() {
    if (!iconHiddenOnSmallScreens) {
      return <span className="u-visually-hidden">{iconMeaning}</span>
    }
    return null
  }

  // generated
  const spanClassNameList = classNames({
    'c-btn ': true,
    'c-tile-select__button ': true,
    'is-disabled ': disabled,
  }).trim()

  // generated
  function renderIfButtonText() {
    if (buttonText) {
      return (
        <span className="c-tile-select__body-bottom">
          <span className={spanClassNameList}>
            <span className="c-btn__text">{buttonText}</span>
          </span>
        </span>
      )
    }
    return null
  }

  const onClickSpecific = (e) => {
    if (!e) return
    if (!e.target) return
    // if ((e.target instanceof HTMLInputElement) === false) return

    if (onClick) {
      onClick(e)
    }
  }

  // generated main result
  return (
    <div {...otherProps} onClick={onClickSpecific} data-testid={testId} className={divClassNameList}>
      <label className="c-tile-select__label" htmlFor={id}>
        <input
          className="c-tile-select__input"
          type={`${inputType === 'radio' ? 'radio' : 'checkbox'}`}
          id={id}
          value={value}
          name={`${inputType === 'radio' ? radioGroupName : id}`}
          defaultChecked={defaultChecked}
          checked={checked}
          disabled={disabled}
          aria-disabled={disabled ? 'true' : 'false'}
          onChange={onChange}
        />
        <span className="c-tile-select__body">
          <span className="c-tile-select__body-top">
            <i className={iClassNameList} aria-hidden="true" />
            {renderUnlessIconHiddenOnSmallScreens()}
            <span className="c-tile-select__title">{title}</span>
            <span className="c-tile-select__text">{children}</span>
          </span>
          {renderIfButtonText()}
        </span>
      </label>
    </div>
  )
}

TileSelect.propTypes = {
  iconMeaning: PropTypes.string, // Bronson template: 'icon-meaning'.
  error: PropTypes.bool, // Bronson template: 'is-error'.
  radioGroupName: PropTypes.string, // Bronson template: 'radio-group-name'.
  inputType: PropTypes.string, // Bronson template: 'input-type'.
  checked: PropTypes.bool, // Bronson template: 'checked'.
  defaultChecked: (props, propName, componentName, ...rest) => {
    if (props[propName] && props.checked !== undefined) {
      return new Error('"defaultChecked" should not be used together with "checked"')
    }
    return PropTypes.bool(props, propName, componentName, ...rest)
  }, // Bronson template: 'checked'.
  icon: PropTypes.string, // Bronson template: 'icon'.
  iconHiddenOnSmallScreens: PropTypes.bool, // Bronson template: 'icon-hidden-on-small-screens'.
  id: PropTypes.string, // Bronson template: 'id'.
  title: PropTypes.string, // Bronson template: 'title'.
  children: PropTypes.node, // Bronson template: 'text'.
  disabled: PropTypes.bool, // Bronson template: 'disabled'.
  buttonText: PropTypes.string, // Bronson template: 'button-text'.
  onClick: PropTypes.func,
  testId: PropTypes.string, // Added for data-testid attribute.
}
