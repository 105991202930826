import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function Table(props) {
  const componentListClass = classNames(
    {
      'c-table-wrapper  ': true,
      'c-table-wrapper--auto  ': props.auto,
      'c-table-wrapper--no-scroll  ': props.noScroll,
      'c-table--colored  ': props.colored,
      'c-table--bordered  ': props.bordered,
      'c-table--highlight  ': props.highlight,
      'c-table--wide  ': props.wide,
      'c-table--narrow  ': props.narrow,
    },
    props.className
  ).trim()

  return (
    <div className={componentListClass}>
      <table>{props.children}</table>
    </div>
  )
}

Table.propTypes = {
  className: PropTypes.string,
  auto: PropTypes.bool,
  noScroll: PropTypes.bool,
  colored: PropTypes.bool,
  bordered: PropTypes.bool,
  highlight: PropTypes.bool,
  wide: PropTypes.bool,
  narrow: PropTypes.bool,
  children: PropTypes.node,
}

Table.Caption = Caption
Table.Thead = Thead
Table.Tbody = Tbody
Table.Tfoot = Tfoot
Table.Tr = Tr
Table.Th = Th
Table.Td = Td

function Caption(props) {
  return <caption>{props.children}</caption>
}

Caption.propTypes = {
  children: PropTypes.string,
}

function Thead(props) {
  return <thead>{props.children}</thead>
}

function Tbody(props) {
  return <tbody>{props.children}</tbody>
}

function Tfoot(props) {
  return <tfoot>{props.children}</tfoot>
}

const childrenProps = {
  children: PropTypes.node,
}

Thead.propTypes = childrenProps
Tbody.propTypes = childrenProps
Tfoot.propTypes = childrenProps

function Tr({ children, ...otherProps }) {
  return <tr {...otherProps}>{children}</tr>
}

Tr.propTypes = {
  children: PropTypes.node,
}

function Th({ children, ...otherProps }) {
  return <th {...otherProps}>{children}</th>
}

Th.propTypes = {
  children: PropTypes.node,
}

function Td({ className, highlight, children, ...otherProps }) {
  const componentListClass = classNames(
    {
      'c-table__cell  ': true,
      'c-table__cell--highlight  ': highlight,
    },
    className
  ).trim()

  return (
    <td className={componentListClass} {...otherProps}>
      {children}
    </td>
  )
}

Td.propTypes = {
  className: PropTypes.string,
  highlight: PropTypes.bool,
  children: PropTypes.node,
}
